<template>
    <div class="dec d-flex justify-content-center">

        <div class="item text-center" style="max-width: 1000px;">
                <div class="titleday d-flex justify-content-center">
                    <h2 class="mt-4"><span data-title="1Decembrie" class="text"><strong>1Decembrie</strong></span></h2><br/>
                </div>
                <h2>Ziua Națională a României</h2>
                <div class="content mb-5">
                    <div class="albastru"></div>
                    <div class="galben"></div>
                    <div class="rosu"></div>
                </div>

            <span class="small text-center fw-bold">

                <p>

                    Cu ocazia Zilei Naționale a României, sărbătorim unitatea, tradițiile și valorile care ne
                    definesc ca popor. 1 Decembrie este ziua în care ne amintim de sacrificiile înaintașilor noștri
                    și de Marea Unire de la 1918, un moment crucial în istoria noastră.
                </p>
                <p>

                    Fie ca această zi să aducă în inimile tuturor românilor, de pretutindeni, mândrie, bucurie și
                    speranță pentru un viitor mai bun.<br />
                </p>
                <p>

                    La mulți ani, români! La mulți ani, România! 🎉✨<br />
                </p>
            </span>

            <img class="mt-5" src="https://www.executori.ro/api/public/static/img/1dec.jpg" alt="1dec"
                style="display:block; margin: auto; max-width: 500px; width: 100%;height: auto;">
        </div>

    </div>

</template>

<style scoped lang="scss">
.dec {
    margin-top: -20px;
    margin-bottom: -20px;
    background-color: white;

}

span {
    margin-bottom: 10px;
}

span p {
    text-indent: 40px;
}

h5 {
    font-weight: 300;
}

.text {
    color: transparent;
    display: inline-block;
    border-radius: 3px;
    background-color: #1d3557;
    position: relative;
    -webkit-background-clip: text;
    background-clip: text;

    &:before {
        content: attr(data-title);
        background: linear-gradient(90deg, #002a83 0%, #ffdb00 51%, #e50000 100%);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 0;
        background-clip: text;
        -webkit-background-clip: text;
        transition: all 1s ease-in-out;
        animation: text-animation 6s infinite;
    }
}


@keyframes text-animation {
    0% {
        width: 0;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0;
    }
}

.item {
    margin-top: 10px;
    margin-bottom: 20px;
}

.titleday {
    margin-bottom: 10px;
    letter-spacing: 1px;
}

.titleday h2 {
    font-weight: 300;
}

.titleday h2 span {
    text-transform: uppercase;
    font-weight: 800;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    animation: other 8s;
    animation-iteration-count: infinite;
}


.albastru {
    height: 40px;
    width: 40px;
    /* background-color: #002a83; */
    background: linear-gradient(to left, #002a83, transparent);
}

@keyframes other {
    0% {
        transform: translate(1px, -3px) rotate(-1deg);
    }

    10% {
        transform: translate(-1px, 3px) rotate(-1deg);
    }

    20% {
        transform: translate(1px, 3px) rotate(0deg);
    }

    30% {
        transform: translate(4px, 3px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, -3px) rotate(-1deg);
    }

    60% {
        transform: translate(-4px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(4px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(-4px, 0px) rotate(1deg);
    }

    100% {
        transform: translate(1px, 1px) rotate(0deg);
    }
}


.galben {
    height: 40px;
    width: 40px;
    background-color: #ffdb00;
}

.rosu {
    height: 40px;
    width: 40px;
    background: linear-gradient(to right, #e50000, transparent);

}
</style>
