<template>
  <div v-if="isLoading" class="loading-bar"></div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      this.isLoading = true;  // Pornim bara de încărcare
      next();
    });
    this.$router.afterEach(() => {
      setTimeout(() => {
        this.isLoading = false;  // Oprim bara de încărcare după navigare
      }, 500); // Ajustați întârzierea, dacă este necesar
    });
  },
};
</script>

<style scoped>
.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--primary-color);
  animation: loading 1s ease-in-out infinite;
  z-index: 999999;
}

/* Animație pentru bara de încărcare */
@keyframes loading {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}
</style>