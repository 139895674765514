<template>
    <div class="winter"></div>
</template>
<style>
.winter {
    position: absolute;
    /* content: ""; */
    width: 100%;
    height: 230px;
    top: 85px;
    background: url(../assets/img/winter.png) center center repeat-x;
    z-index: 99;
}


@media (max-width: 576px) {


    .winter{
        top: -10px!important;

    }
}

@media (max-width: 992px) {

    .winter{
        top: 145px;

    }
}

@media (max-width: 768px) {

    .winter {
        top: 200px;

    }
}
</style>
