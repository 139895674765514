<template>
  <NationalDay v-if="nationalDay" /> <!--1december-->

    <Header />

    <div class="container mt-5">
        <div class="row">
            <div class="col-lg-3">
                <div class="card">
                    <h5 class="text-center mt-2">Utile</h5>
                </div>

                <div class="card mt-3 mb-3 useful">
                    <div class="card-body">
                        <div class="fb-page facebook" data-href="https://www.facebook.com/profile.php?id=61560413607750"
                            data-width="" data-hide-cover="false" data-show-facepile="false"></div>
                    </div>

                    <div class="card-body">
                        <h5 class="card-title mb-3" style="border-left: 5px solid #1E90FF; padding-left: 10px;">
                            UniversulJuridic.ro</h5>
                        <a href="https://www.universuljuridic.ro/" class=""><img style="max-width: 100%; height: auto;"
                                src="../assets/img/uj.png" alt="uj"></a>
                    </div>

                    <div class="card-body">
                        <h5 class="card-title" style="border-left: 5px solid #1E90FF; padding-left: 10px;">Despre
                            executarea silită</h5>
                        <div class="card-text small py-2">Executorul judecatoresc este un organ de executare numit
                            in
                            functie de
                            Ministrul Justitiei care..</div>
                        <iframe style="max-height: 130px; width: 100%;" src="https://www.youtube.com/embed/nf9F4Ob3las"
                            title="executare">
                        </iframe>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title" style="border-left: 5px solid #1E90FF; padding-left: 10px;">REPES</h5>
                        <div class="card-text small py-2">Registrul de publicitate a vânzării de bunuri supuse
                            executării silite</div>
                        <iframe style="max-height: 130px; width: 100%;" src="https://www.youtube.com/embed/ZXAJq4G4V3Y"
                            title="repes">
                        </iframe>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title" style="border-left: 5px solid #1E90FF; padding-left: 10px;">Cum să
                            devin
                            executor</h5>
                        <div class="card-text small py-2">Executor judecătoresc poate fi persoana care îndeplineşte
                            următoarele condiţii:
                            a. are cetăţenia română şi domiciliul în România..</div>
                        <router-link to="/informatii-generale/admitere" class="btn blue-btn">DESCHIDE</router-link>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title" style="border-left: 5px solid #1E90FF; padding-left: 10px;">Newsletter
                        </h5>
                        <div class="card-text small py-2">Academia de Științe Juridice din România</div>
                        <img src="../assets/img/asjr.png" alt=""
                            style="height: 100px; display: block; margin: auto;"><br />
                        <router-link to="/newsletter" class="btn blue-btn">DESCHIDE</router-link>
                    </div>
                </div>
            </div>

            <div class="col-lg-9">
                <div class="card sticky-div news-title mb-3" v-scroll="handleScroll" :class="{ 'is-sticky': isSticky }"
                    ref="stickyDiv">
                    <h5 class="text-center mt-2">Noutăți UNEJ</h5>
                </div>

                <div class="card mb-3" v-for="item in items" :key="item.id" :id="item.id">
                    <div class="row">
                        <div class="px-7">
                            <div class="card-block px-3">

                                <h5 class="card-title mt-3">{{ item.title }}</h5>
                                <div class="data"><i class="bi bi-calendar px-1"></i><small>Publicat la:
                                        {{ item.data.slice(0, 10) }} {{ item.data.slice(11, 19) }}</small></div>
                                <div class="line mt-2" :style="{ 'background-color': `${item.importance}` }"></div>
                                <div class="card-text">

                                    <div style="font-size: small;" v-html="item.html" />
                                    <div style="font-size: small; color: red; text-align: center;"
                                        v-if="item.hasEllipse">(Click
                                        pe
                                        articol pentru
                                        expandare/restrângere)</div>
                                    <div style="font-size: small;" v-html="item.ellipse" v-if="item.hasEllipse"
                                        @click="toggleEllipsis"></div>
                                    <div style="font-size: small;" v-html="item.html2" v-if="item.hasContent2">
                                    </div>
                                    <img class="item_image" :src=item.image
                                        style="width: 100%; height: auto; display: block; margin: auto;" />
                                    <p v-if="item.caption" class="text-center">
                                        {{ item.caption }}
                                    </p>
                                    <div class="MyGallery mb-3 mt-3">
                                        <MyGallery v-if="item.gallery && item.gallery.length" :gallery="item.gallery" />
                                    </div>
                                </div>

                                <div class="mb-3" v-for="item in item.links" :key="item.name">
                                    <a class="btn btn-link" :href=item.link target="_blank">{{ item.name }}</a>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <RSS />
</template>


<script>
import Header from "../components/Header.vue"
import RSS from "../components/RSS.vue"
import MyGallery from "../components/Gallery.vue"
import axios from 'axios'
import NationalDay from '../components/NationalDay.vue'

export default {
    data() {
        return {
            rss: [],
            items: [],
            loading: true,
            errorMsg: "",
            index: null,
            allNews: 0,
            pageSize: 10,
            page: 0,
            previousScroll: 0,
            nationalDay: false,
        }
    },
    components: {
        MyGallery,
        RSS,
        Header,
        NationalDay
    },
    methods: {
        handleScroll() {
            if (window.scrollY - this.previousScroll > 200 && !this.finished) {
                this.previousScroll = window.scrollY;
                this.page = this.page + 1;
                if (this.page >= this.allNews / 10) {
                    this.finished = true;
                } else {
                    this.getMoreContent(this.pageSize * this.page, this.pageSize);
                }
            }
        },

        async getMoreContent(offset, limit) {
            axios
                .get(
                    `https://www.executori.ro/api/public/getNewsData?offset=${offset}&limit=${limit}`
                )
                .then((response) => {
                    this.allNews = response.data.allNews.allNews;
                    this.items = this.items.concat(response.data.news);
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(() => this.loading = false)

        },
        toggleEllipsis() {
            var element = document.getElementById("ellipsis-ex");
            element.classList.toggle("less-text");
        },

    },
    mounted() {
        this.getMoreContent(0, 10);
        window.addEventListener("scroll", this.handleScroll);
    },
    // created() {
    //     let month = new Date().getMonth();
    //     let day = new Date().getDate();

    //     if (month == 11 && day == 1) {
    //         return this.nationalDay = true;
    //     };
    // }
    created() {
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.5&appId=198998956798670";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        // national day
        let month = new Date().getMonth();
        let day = new Date().getDate();
        if (month == 11 && day == 1) {
            return this.nationalDay = true;
        };
    }
}
</script>

<style scoped>
.line {
    height: 5px;
    width: 150px;
    border-radius: 10px;
    margin-bottom: 15px;
}

.useful {
    position: sticky;
    top: -500px;
}
</style>